/* Imports */
import { defineAsyncComponent } from 'vue';

/* Helpers */
import { capitalize as capitalizeUtil } from '@garmin/text-utils';

/**
 * @returns {object} - The getComponents function.
 */
export function useGetComponents() {
  const getComponents = (content) => {
    const componentList = [];

    const capitalize = (string) => capitalizeUtil(string);

    const addComponent = (displayType) => {
      const component = capitalize(displayType);
      componentList.push({
        label: displayType,
        component: defineAsyncComponent(() => import(`../components/${component}.vue`)),
      });
    };

    const processReference = (ref) => {
      if (ref?.fields?.displayType) {
        addComponent(ref.fields.displayType);
      } else if (ref?.sys?.contentType?.sys?.id) {
        addComponent(ref.sys.contentType.sys.id);
      }
    };

    if (content?.references?.length) {
      content.references.forEach((ref) => {
        processReference(ref);
      });
    }

    /*
     * Handle Rich Text Separately since its a level deeper
     */
    if (content?.content?.content?.length) {
      content.content.content.forEach((ref) => {
        if (ref?.data?.target?.fields?.displayType) {
          addComponent(ref.data.target.fields.displayType);
        } else if (ref?.data?.target?.sys?.contentType?.sys?.id) {
          addComponent(ref.data.target.sys.contentType.sys.id);
        }
      });
    }

    return componentList;
  };

  return { getComponents };
}

export default useGetComponents;
