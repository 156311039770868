/* Imports */
import axios from 'axios';

/* Helpers */
import { hasCDNCache } from '../utils/mainUtils.js';

export default {
  state: {
    kickers: [],
    productIds: {},
    skuPromos: {},
  },
  getters: {
    getKickerFlags(state) {
      return state.kickers;
    },
    getKickerByPid: (state, getters) => (pid, productIds) => {
      const kickerObj = getters.getKickerFlags?.length > 0
        ? getters.getKickerFlags.filter(
          (kicker) => (kicker?.productId === pid || kicker?.productId === productIds?.[0]),
        )
        : [];
      return kickerObj?.length !== 0 && kickerObj?.[0]?.productId ? kickerObj?.[0]?.kicker?.replace(/<[^>]+>/g, '') : null;
    },
    getProductIds(state) {
      return state.productIds;
    },
    getSkuPromos(state) {
      return state.skuPromos;
    },
  },
  mutations: {
    setKickers: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.kickers = value;
    },
    setProductIds: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.productIds = value;
    },
    setSkuPromos: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.skuPromos = value;
    },
  },
  actions: {
    loadKickers: async ({ commit, getters, dispatch }) => {
      dispatch('createListOfIds');
      const bodyObject = {
        countryCode: getters.getCountryCode,
        honorDates: true,
        locale: getters.getLocale,
        kickerRequests: getters.getSkuPromos,
      };
      if (hasCDNCache(window.location.href)) bodyObject.cdncache = false;

      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getKickerFlags`;
      const payload = bodyObject;

      try {
        const response = await axios.post(url, payload);

        if (response.status !== 200) {
          commit('setKickers', []);
        }
        commit('setKickers', response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching kicker flags: ', error);
        commit('setKickers', []);
      }
    },

    createListOfIds: ({ commit, getters, dispatch }) => {
      // TODO: REWRITE THIS
      // for products with group false and productIds are not available,
      // use the product Id for the kickers call
      const listPids = getters.getProducts.filter(
        (product) => (product?.productIds.length === 0),
      ).map((product) => ({
        [product.id]: getters.getPromotionGuidsByPid(product.id),
      }));
      // for products with group true and productIds available, use the first pid from
      // the productIds seriesArray to make the kickers call
      const listFirstPid = getters.getProducts.filter(
        (product) => (product.productIds.length !== 0),
      ).map((product) => ({
        [product.productIds[0]]: getters.getPromotionGuidsByPid(product.id),
      }));

      commit('setProductIds', Object.assign(...listPids ?? [], ...listFirstPid ?? []));
      dispatch('createSkuPromos');
    },
    createSkuPromos: ({ commit, getters }) => {
      // all the product id - skus mapping
      const products = getters.getProductIds;
      let kickerRequests = {};

      Object.keys(products).forEach((key) => {
        // all the skus for 1 product
        const productSkus = products[key];
        // all the promotionGuids for all the skus of 1 product
        if (productSkus) {
          let promotionGuids = Object.values(productSkus)
            .filter((item) => item !== null)
            .map((item) => item[0].promotionGuid);
          // no duplicates
          promotionGuids = [...new Set(promotionGuids)];

          kickerRequests = { ...kickerRequests, ...{ [key]: promotionGuids } };
        }
      });

      commit('setSkuPromos', kickerRequests);
    },
  },
};
