/* eslint-disable camelcase */
/* Imports */
import router from '../router/router.js';

export default {
  state: {
    queryParams: {},
  },
  getters: {
    getQueryParams: (state) => state.queryParams,
  },
  mutations: {
    setQueryParams: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.queryParams = value;
    },
  },
  actions: {
    setQueryParams: async ({ commit, getters, dispatch }, payload = {}) => {
      /**
       * If compare / compareProduct query params are set in the current url,
       * and compare / compareProduct are not being explicitly passed in the payload,
       * then persist the current compare / compareProduct query params.
       */
      const { compare, compareProduct } = getters.getQueryParams;
      if (compare && !payload?.compare) {
        // eslint-disable-next-line no-param-reassign
        payload.compare = compare;
      }
      if (compareProduct && !payload?.compareProduct) {
        // eslint-disable-next-line no-param-reassign
        payload.compareProduct = compareProduct;
      }

      const queryParamsObj = payload;

      // loadData: this value is true if it's necessary to make an API call to fetch the products, prices and filters
      const { loadData, initialLoad } = queryParamsObj;
      delete queryParamsObj?.loadData;

      if (initialLoad) {
        delete queryParamsObj.initialLoad;
      } else {
        // If the user is on the page and they change the sort order
        // we want to update the query params, but remove the skipRouter
        // from the url

        const queryPayload = payload;
        delete queryPayload.skipRouter;

        router.push({ path: '/', query: queryPayload });
      }
      commit('setQueryParams', payload); // allowed sorter to be set on the queryParams above

      // but removing the sorter before deciding whether to display grouped or ungrouped products
      // since the sorter should not affect this strippedPayload will contain
      // everything in payload except the sorter and currentPage key:value pairs
      // Remove all the utm_* params that are passed for email campaigns because we still want to
      // display groups for those
      const {
        sorter,
        currentPage,
        utm_medium,
        utm_source,
        utm_campaign,
        utm_content,
        utm_term,
        cdncache,
        skipRouter,
        fbclid,
        ...strippedPayload
      } = payload;

      commit('setCurrentPage', currentPage || 1);

      // Ignore empty query params
      Object.entries(strippedPayload).forEach(([key, value]) => {
        if (value === null) {
          delete strippedPayload[key];
        }
      });

      const shouldLoadDisplayGroup = Object.keys(strippedPayload)?.length || getters.getCompareActiveState;

      if (shouldLoadDisplayGroup) {
        dispatch('loadDisplayGroup', false);
      } else {
        dispatch('loadDisplayGroup', true);
      }

      const shouldLoadData = loadData || initialLoad;
      if (shouldLoadData) {
        commit('setIsLoading', true);
        const promises = [];
        const products = await dispatch('loadProducts');
        promises.push(products);
        if (getters.getHasFilters) {
          const filters = dispatch('loadFilters');
          promises.push(filters);
        }

        Promise.all(
          promises,
        ).then(() => {
          commit('setIsLoading', false);
        }).catch((error) => {
          commit('setIsLoading', false);
          // TODO: Display an alert that there was an error loading products
          // eslint-disable-next-line no-console
          console.error('An error occurred:', error);
        });
      }
    },
  },
};
