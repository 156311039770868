/* Imports */
import { getAndUpText } from '@garmin/locale-and-up-text';

const defaultPricingData = Object.freeze({
  listPrice: null,
  salePrice: null,
  savings: null,
  unformatted: {
    listPrice: null,
    salePrice: null,
    savings: null,
  },
  andUpText: null,
  skuPromotions: {},
});

export default {
  state: {
    pricesLoading: true,
    pricingData: [],
  },
  getters: {
    getPricingData: (state) => state.pricingData,
    getPricesLoading: (state) => state.pricesLoading,
    getPriceByPid: (state, getters) => (pid) => {
      const prices = getters.getPricingData;
      return prices.find((price) => price?.id === pid);
    },
    getPromotionGuidsByPid: (state, getters) => (pid) => {
      // fix this because its being called before pricing is done and after
      const price = getters.getPriceByPid(pid);
      return price ? price.skuPromotions : [];
    },
  },
  mutations: {
    setPricingData: (state, payload) => {
      // eslint-disable-next-line no-param-reassign
      state.pricingData = payload;
    },
    setPricesLoading: (state, payload) => {
      // eslint-disable-next-line no-param-reassign
      state.pricesLoading = payload;
    },
  },
  actions: {
    setPricesLoading: ({ commit }, payload) => {
      commit('setPricesLoading', payload);
    },
    setPricingData: ({ commit }, payload) => {
      commit('setPricingData', payload);
    },
    formatPricingData: ({ rootGetters }, payload) => {
      const { getLocale } = rootGetters;
      const pricingData = payload || [];

      const derivePricingData = (config) => {
        const { value, id } = config || {};
        if (!value || !id) {
          throw new Error('Expected value and id to be passed via config to derivePricingData');
        }

        let pricedSkusResult = [];
        if (Array.isArray(value)) {
          pricedSkusResult = value.map((pidPriceData) => pidPriceData?.pricedSkus).flat();
        } else {
          const pricedSkus = value?.pricedSkus || [];
          pricedSkusResult = [...pricedSkus];
        }

        // If there are no pricedSkus, bail out early with null pricing data object
        if (pricedSkusResult.every((pricedSku) => pricedSku === undefined)) {
          return { ...defaultPricingData, id };
        }

        // We need to get a list of all the sku prices to determine if they are all the same price
        // Use the salePrice if available, otherwise use the listPrice
        const skuPrices = pricedSkusResult.map((skuPriceData) => (skuPriceData?.salePrice?.price ?? skuPriceData?.listPrice?.price ?? null));

        // Handle andUpText logic
        const computeAndUpText = (prices, locale) => {
          const filterOutNullValues = (price) => price;
          // If there are any variations in the related sku prices, we'll add "and up" text
          if (prices.filter(filterOutNullValues).some((price) => price.toString() !== skuPrices[0].toString())) {
            return getAndUpText(locale);
          }
          return null;
        };
        const andUpText = computeAndUpText(skuPrices, getLocale);

        // Handle sku promotions
        const consolidatedSkuPromotions = {};
        const consolidateSkuPromotions = (skuPriceData) => {
          if (skuPriceData?.partNumber && skuPriceData?.appliedPromotionGuids?.[0]) {
            consolidatedSkuPromotions[skuPriceData.partNumber] = [{
              promotionGuid: skuPriceData.appliedPromotionGuids[0],
            }];
          }
        };
        // Loop over all priced sku results and consolidate the sku promotions into 1 object
        pricedSkusResult.forEach(consolidateSkuPromotions);

        // Handle formatting sku prices
        const formatSkuPrices = (skuPriceData) => ({
          listPrice: skuPriceData?.listPrice?.formattedPrice ?? null,
          salePrice: skuPriceData?.salePrice?.formattedPrice ?? null,
          savings: skuPriceData?.savings?.formattedPrice ?? null,
          unformatted: {
            listPrice: skuPriceData?.listPrice?.price ?? null,
            salePrice: skuPriceData?.salePrice?.price ?? null,
            savings: skuPriceData?.savings?.price ?? null,
          },
        });

        // Handle consolidating sku prices
        const consolidateSkuPrices = (prev, current) => {
          // Use salePrice if it exists, otherwise, use the listPrice
          const currentPrice = current?.unformatted?.salePrice ? current.unformatted.salePrice : current?.unformatted?.listPrice;
          const prevPrice = prev?.unformatted?.salePrice ? prev?.unformatted?.salePrice : prev?.unformatted?.listPrice;

          // Consolidate the sku prices to the lowest price
          return prevPrice < currentPrice ? prev : current;
        };

        const formattedPricedSkus = pricedSkusResult
          .map(formatSkuPrices)
          .reduce(consolidateSkuPrices);

        const pricingDataResult = {
          id,
          andUpText,
          ...formattedPricedSkus,
          skuPromotions: consolidatedSkuPromotions,
        };

        return pricingDataResult;
      };

      const formattedPricingData = pricingData.map((result) => {
        const { value, id } = result || {};
        // If the promise for fetching resolved successfully,
        // the result will contain a value object with the pricing API response data
        if (value && id) {
          return derivePricingData({ value, id });
        }
        // If the promise for fetching rejected (e.g. errored out)
        // the result will not contain a value object with the pricing API response data
        // and we will supply a null data pricing object to use on the front-end
        return { ...defaultPricingData, id };
      });

      return formattedPricingData;
    },
    async fetchPricingData({ rootGetters }, payload) {
      const {
        getCustomerGroups,
        getCountryCode,
        getBuyServiceBase,
        getLocale,
      } = rootGetters;
      const {
        id,
        group,
      } = payload;
      // If group is true on the product object, use the groupId API, otherwise use the PID API
      const pricingApiType = group ? 'group' : 'pid';

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
       * If there are multiple customerGroups, they will be divided by a |
       * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
       */
      queryParams.append('customerGroup', getCustomerGroups);

      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);

      const requestUrl = `${getBuyServiceBase}/pricing-proxy-services/countries/${getCountryCode}/${pricingApiType}/${id}/prices?${queryParams}`;
      try {
        /**
         * We need to send cookies with this request to pass along the GarminBuyReferrer cookie
         * Because of this, we are using fetch with credentials: 'include' instead of axios
         * axios with the withCredentials: true setting is not correctly sending cookies
         * with the request. More info here: https://github.com/axios/axios/issues/2149
         */
        const response = await fetch(requestUrl, {
          method: 'get',
          credentials: 'include',
        });

        const priceData = await response.json();
        return priceData;
      } catch (error) {
        throw new Error(`Error encountered in Pricing - ${error.message}`);
      }
    },
    async fetchPrices({ commit, dispatch, rootGetters }) {
      const { getProducts } = rootGetters;

      commit('setPricesLoading', true);
      await dispatch('setCustomerGroups');
      const products = getProducts;
      const productIds = Array.isArray(products) && products.map((product) => product.id);
      if (!productIds.length) {
        // If there are no productIds, set the pricingData to an empty array
        commit('setPricingData', []);
      } else {
        const promises = products.map((product) => dispatch('fetchPricingData', product));
        const pricingResponses = await Promise.allSettled(promises);
        // Add productId back to pricing responses
        const pricingResults = pricingResponses.map((result, index) => ({ ...result, id: productIds[index] }));
        // Format pricing data and fetch currency code in parallel
        const [formattedPricingData] = await Promise.all([dispatch('formatPricingData', pricingResults)]);
        commit('setPricingData', formattedPricingData);
      }
      commit('setPricesLoading', false);
      dispatch('loadKickers');
    },
  },
};
