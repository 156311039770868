export default {
  state: {
    contentfulEnvSettings: {},
    colorSwatchEnabled: false,
  },
  getters: {
    getContentfulEnvSettings: (state) => state.contentfulEnvSettings,
    getColorSwatchEnabled: (state) => state.colorSwatchEnabled,
  },
  mutations: {
    setContentfulEnvSettings: (state, payload) => {
      // eslint-disable-next-line no-param-reassign
      state.contentfulEnvSettings = payload;
    },
    setColorSwatchEnabled: (state, payload) => {
      // eslint-disable-next-line no-param-reassign
      state.colorSwatchEnabled = payload;
    },
  },
  actions: {
    fetchContentfulEnvSettings({ commit }) {
      commit('setContentfulEnvSettings', window?.content?.envSettings || {});
      commit('setColorSwatchEnabled', Boolean(window?.content?.envSettings?.ITFE_CATEGORY_PAGES_COLOR_SWATCH));
    },
    setContentfulEnvSettings({ commit }, payload) {
      commit('setContentfulEnvSettings', payload);
    },
    setColorSwatchEnabled({ commit }, payload) {
      commit('setColorSwatchEnabled', payload);
    },
  },
};
