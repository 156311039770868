/**
 * Check if the url has the cdncache=false query string
 * @param {string} url - The url to check
 * @returns {boolean}  - True if the url has the cdncache=false query string || False if the url does not have the cdncache=false query string
 * @example
 * hasCDNCache('https://example.com?cdncache=false'); // true
 * hasCDNCache('https://example.com'); // false
 */
export const hasCDNCache = (url) => url.includes('cdncache=false');

export default hasCDNCache;
