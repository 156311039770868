<script setup>
/* Imports */
import { computed, onBeforeMount } from 'vue';

/* Helpers */
import { mapActions, mapGetters } from './utils/mainUtils.js';

/* Composables */
import { useGenericAnalyticsDataHelper } from './composables/useAnalyticsData.js';

/* Components */
import Page from './containers/Page.vue';

/* Initialize Composables */
const { genericAnalyticsData } = useGenericAnalyticsDataHelper();

/* Store - Actions */
const {
  loadInitialState,
} = mapActions();

/* Store - Getters */
const {
  getAppIsReady: appIsReady,
  getContent: content,
  getLocale: locale,
  getBuyServiceBase: buyServiceBase,
  getPublicPath: publicPath,
} = mapGetters();

/* Computed */
const genericLinkAnalyticsData = computed(() => {
  const allowedGenericAnalyticsDataKeys = [
    'click_category',
    'country_code',
    'currency_code',
    'employee_flag',
    'language_code',
    'login_status',
    'page_name',
    'page_type',
  ];

  const commonLinkAnalyticsData = {
    ...genericAnalyticsData(),
    click_category: 'Content',
  };

  const commonLinkAnalyticsDataKeys = Object.keys(commonLinkAnalyticsData);

  // Delete fields from the commonLinkAnalyticsDataKeys object that we don't want to send
  // with the generic link analytics data
  commonLinkAnalyticsDataKeys.forEach((key) => {
    if (!allowedGenericAnalyticsDataKeys.includes(key)) {
      delete commonLinkAnalyticsData[key];
    }
  });

  return commonLinkAnalyticsData;
});

/* Handle data on page load */
onBeforeMount(() => {
  loadInitialState();
});
</script>

<template>
  <g-global-styles />
  <div
    v-if="appIsReady"
    id="app"
    v-utag-link-handler-event="genericLinkAnalyticsData"
  >
    <Page
      :content="content"
      :locale="locale"
      :buy-service-base="buyServiceBase"
      :public-path="publicPath"
    />
  </div>
</template>

<style lang="scss">
#app {
  @include font-primary();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-primary;
  min-height: 100vh;
}

// Global style needed to override scoped @garmin/component style
.gc__dropdown__select {
  font-family: inherit; // This will inherit the locale specific font-families
}

.visuallyhidden {
  @include visually-hidden();
}
</style>
